.p-datatable .p-datatable-thead > tr > th {
    text-align: center!important;
    padding: 0.25rem 0.25rem !important;
    font-size: 0.90rem !important;
    border-width: 0 0 1px 0;
    font-weight: 500;
    color: #000000 !important;
    background: #dfdfdf !important;
    transition: none;
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
    color: #FFFFFF !important;
}

.p-datatable .p-datatable-tbody > tr {
    font-size: 0.90rem !important;
}

.p-inputtext {
    font-size: 0.80rem !important;
    color: rgba(0, 0, 0, 0.87);
    -webkit-appearance: none;
    appearance: none;
    border-radius: 4px;
}

.p-paginator {
    background: #6d6d6d17 !important;
    color: #373737 !important;
    padding: 0rem !important;
    justify-content: center !important;
    border-radius: 4px;
    padding: 0rem!important;
    margin: 0rem!important;
}
.p-paginator-icon{
    opacity: 50%!important;
    color: #373737 !important;
}
.p-paginator .p-paginator-pages .p-paginator-page {
    background-color: transparent;
    border: 0 none;
    color: rgba(0, 0, 0, 0.6);
    min-width: 2rem!important;
    height: 2rem!important;
    padding: 0rem!important;
    margin: 0.143rem;
    transition: none;
    border-radius: 50%;
}

.p-datatable {
    background: #03a9f42e !important;
    border: 1px solid rgba(255, 255, 255, 0.95);
    border-width: 0 0 1px 0;
    padding: 0.15rem;
    margin: 0.15rem!important;
    border-radius: 2px !important;
    font-weight: 500;
}
.p-col-4 {
    padding: 0.2rem!important;
}
.p-datepicker-touch-ui, .p-calendar .p-datepicker-touch-ui {

    min-width: 25vw !important;
    max-width: 50vw !important;
    font-size: small !important;
    position: fixed!important;
    top: 50%!important;
    left: 50%!important;
}

.p-datatable .p-datatable-header {
    padding: 0rem!important;
    background: rgba(109, 109, 109, 0) !important;
    border: 0px!important;
    border-radius: 10px !important;
}

.p-button-tcs {
    color: #ffffff !important;
    height: 2rem !important;
    width: 2rem !important;
    margin-left: 0.1rem !important;
}
.p-button-tcs-grlback {
    background-color: rgba(255, 254, 255, 0.02) !important;
    color: rgba(3, 169, 244, 0.45) !important;
    margin-top: 0rem !important;
    font-size: 1.2em!important;
}
.p-button-loading{
    color: #d83e25 !important;
    background: rgba(255, 243, 244, 0) !important;
    font-size: 1.6em!important;
    margin-left: 0.1rem !important;
}
.p-datatable .p-datatable-tbody > tr > td {
    padding: 0.5rem 0.5rem!important;
}

.card {
    margin: 0.5rem;
    border-radius: 10px !important;
    /*border: 2px solid #76a728 !important;*/

}

.header-scr {
    background: rgba(238, 238, 238, 0.69) !important;
    border: 0px!important;
    padding: 0.15rem;
    border-radius: 10px !important;
    margin: 0px!important;
}
.p-progressbar {
    border: 0 none;
    height: 5px!important;
    background: rgba(24, 181, 108, 0.32);
    border-radius: 6px!important;
}

.p-column-filter {
    height: 3em!important;
    /*text-align: left;*/
    font-size: small !important;
    border-width: 0 0 1px 0;
    font-weight: 500;
    color: #000000 !important;
    background: #ffffff !important;
    transition: none;
}
.p-column-filter-date {
    height:  2em!important;
    margin: 0px!important;
    padding: 0px!important;
    margin-top:0px!important; ;
    /*centertext-align: left;*/
    font-size: small !important;
    border-width: 0 0 1px 0;
    font-weight: 500;
    color: #000000 !important;
    background: #ffffff !important;
    transition: none;
}
.p-inputtext .p-column-filter-date{
    padding: 1px!important;
}