.span-mrg {
  margin-top: 0rem !important;
  font-size: small !important;
  color:#A5A5A5!important;
}

.validate_required {
  font-weight: bold;
  color: red;
  font-size: small;
}

.span-mrg-icon {
  border-radius: 0.5rem 0rem 0rem 0.5rem !important;
  border-right: 0px !important;
  background-color: #eceff1 !important;
  color:  #ea0e0e !important;
  padding: 0.6rem !important;
}

.span-mrg-input {
  border-radius: 0rem 0.5rem 0.5rem 0rem !important;
  padding: 0.1rem !important;
  font-size: small !important;
}

.span-mrg-input-no-icon {
  border-radius: 0.5rem !important;
  padding: 0.5rem !important;
  font-size: small !important;
}

.span-mrg-icon-p {
  border-radius: 0rem 0.5rem 0.5rem 0rem !important;
  padding: 0.6rem !important;
  font-weight: bold;
  color: #060611 !important;
}

.span-mrg-input-p {
  border-radius: 0rem !important;
  padding: 0.5rem !important;
  border-right: 0px !important;
  font-size: small !important;
}

.span-mrg-input-no-icon-p {
  border-radius: 0.5rem 0rem 0rem 0.5rem !important;
  padding: 0.5rem !important;
  border-right: 0px !important;
}

.button-icon-p {
  background-color: #ff1313 !important;
  color: #ff1313 !important;
}

.span-mrg-input-date {
  border-radius: 0rem 0.5rem 0.5rem 0rem !important;
  padding: 0.5rem !important;
  background-color: #4DADD9 !important;
}

.p-inputtext.p-component .span-mrg-input-date {
  background-color: #d97289 !important;
}