.span-mrg-multi {
  height: 2.25rem !important;
  padding: 0rem!important;
  vertical-align: middle!important;
  font-size: small;
}

.validate_required {
  font-weight: bold;
  color: red;
  font-size: small;
}

.span-mrg-icon-multi {
  border-radius: 0.5rem 0rem 0rem 0.5rem !important;
  border-right: 0px !important;
  padding: 0rem !important;
  background-color: #eceff1 !important;
  color:  #ea0e0e !important;

}

.span-mrg-input-multi {
  border-radius: 0rem 0.5rem 0.5rem 0rem !important;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.1rem 0.1rem !important;
  margin-right: 0.1rem !important;
  background: rgba(63, 81, 181, 0) !important;
  color:#523939!important;
  font-size: small;
}

.p-inputgroup .p-inputtext, .p-fluid .p-inputgroup .p-inputtext, .p-inputgroup .p-inputwrapper, .p-fluid .p-inputgroup .p-input {
  width: 100% !important;
  font-size: small !important;
}
.p-multiselect-panel{
  border-radius: 1rem!important;
}
.p-multiselect-panel .p-multiselect-header {
  border-radius: 0.5rem!important;
  border-color: rgba(63,81,181,0)!important;
}

.p-multiselect-panel .p-multiselect-header {
  background-color: rgba(170, 170, 170, 0) !important;
}
.p-multiselect-panel  {
  background-color: rgba(208, 208, 208, 0.9) !important;
}