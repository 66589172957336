@import "../../../assets/scss/colors.scss";

.custom-dropdown {
    width: 30vh;
    border: none !important;
}

.custom-dropdown:focus-within {
    box-shadow: inset 0 0 0 1px transparent !important;
}
