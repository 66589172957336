$primaryColor: #ed2228;

.active-nav-item {
  color: $primaryColor !important;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: white !important;
  background-color: $primaryColor !important;
  //  background-color: transparent!important;
  //  color : $primaryColor !important;
}

.navbar-light .navbar-nav .nav-link:active,
.navbar-light .navbar-nav .nav-link:active {
  color: $primaryColor !important;
}

.custom-header {
  padding: 0.2rem !important;
}
