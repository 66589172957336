@import url("https://use.fontawesome.com/releases/v5.7.1/css/all.css");
@import "./colors.scss";
@import "../fonts/index.scss";

body {
  font-family: "ooredoobold";
}

.form-control:focus {
  outline: 0 !important;
  border-color: initial;
  box-shadow: none;
}

.form-control {
  font-weight: 600;
}

/** eliminate input number arrows **/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-input-placeholder {
  font-size: small !important;
}

.form-control {
  font-weight: normal !important;
}

ul li {
  list-style: square;
}

/******************************************************** CUSTOMIZED ********************************************************/

.sign-label {
  font-family: "ooredooregular";
}

.copyright {
  font-family: "ooredooregular";
}

.custom-header {
  background-color: #ecececf1 !important;
  padding: 0.2rem;
  //  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.error-msg-form,
.msg-field-error {
  color: $ooredooRedColor !important;
}

.error-validation-form {
  border: 1px solid $ooredooRedColor;
}

.tr-form {
  border: 1px solid gray;
}

.custom-icon-button {
  //border-radius: 50px;
  border: 1px solid gray;
  height: 30px;
  width: 30px;
  padding: 0;
}

.footer-container {
  margin: 0 25%;
  width: 50%;
  text-align: center;
}

.active-link {
  background-color: $ooredooRedColor;
  color: white;
}

.dropdown-item.active,
.dropdown-item:active {
  color: black;
  background-color: transparent;
}

.file-chosen-container {
  border: 1px dashed;
  height: 100px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.access-denied-content {
  text-align: center;
  margin-top: 6%;
  font-size: 20px;
  width: fit-content;
}

.master-container {
  opacity: 0.9;
  background-image: url(../img/backG.jpg);
  background-repeat: no-repeat;
  background-position: 100%;
  background-color: hsla(0, 0%, 92.5%, 0.9450980392156862) !important;
}

.master-container2 {
  background-color: hsla(0, 0%, 92.5%, 0.9450980392156862)!important
}

.btn-primary {
  background-color: $ooredooRedColor;
  border-color: $ooredooRedColor;
}

.btn-primary:hover {
  background-color: $ooredooHoverRedColor;
  border-color: $ooredooHoverRedColor;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: $ooredooHoverRedColor;
  border-color: $ooredooHoverRedColor;
}

.btn-primary:focus,
.btn-primary.focus {
  background-color: $ooredooHoverRedColor;
  border-color: $ooredooHoverRedColor;
}

.MuiTableCell-root {
  width: 80%;
}

.MuiTypography-root {
  font-family: "ooredoobold";
}

.MuiTypography-h6 {
  font-family: "ooredoobold" !important;
}
